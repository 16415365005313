import React from "react";
// javascript plugin used to create scrollbars on windows
import { Route, Switch, Redirect } from "react-router-dom";
import NotificationAlert from 'react-notification-alert';
import Footer from "components/Footer/Footer.js";
import ReactMarkdown from 'react-markdown/with-html';
import { Gx } from "helper/ApiCaller.js";
import {
    Row, Col, Spinner, UncontrolledDropdown, Button, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import {
    Modal, ModalBody, ModalFooter
} from "reactstrap";

import routes from "routes.js";

import store from 'store';


class MpLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: "#eaffd5",
            activeColor: "info",
            fetchingMsg: "",
            isFetching: false,
            pageTitle: "",
            mounted: false,
            user: store.get("user")
        };

        this.isFetching = this.isFetching.bind(this);
        this.setPageTitle = this.setPageTitle.bind(this);
        this.authUser = this.authUser.bind(this);

        this.resetIdleTimeout = this.resetIdleTimeout.bind(this);
        this.logout = this.logout.bind(this);

        this.idleTime = 1000*60*30 //30mins
        this.idleTimeout = null;
    }
    componentDidMount() {
        this.setState({ mounted: true }, () => {
            this.resetIdleTimeout()
            let events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
            events.forEach((name) => {
                window.addEventListener(name, this.resetIdleTimeout);
            });
        })
    }

    componentWillUnmount() {

    }
    componentDidUpdate(e) {
        document.scrollingElement.scrollTop = 0;
    }


    resetIdleTimeout() {
        if (this.idleTimeout) clearTimeout(this.idleTimeout);
        this.idleTimeout = setTimeout(this.logout, this.idleTime);
    }

    logout() {
        if (this.props.history.location.pathname !== "/guest/OtpLogin") {
            alert("Session has been idle over its time limit.\nIt will be sign out now.");
            store.clearAll();
            this.props.history.push("/guest/OtpLogin");
        }
    }

    handleActiveClick = (color) => {
        this.setState({ activeColor: color });
    };
    handleBgClick = (color) => {
        this.setState({ backgroundColor: color });
    };

    scrollTo(scrollTop) {
        //  let mp = document.querySelector(".main-panel");
        //mp.scrollTop = scrollTop;
        window.scroll({ top: scrollTop, left: 0, behavior: 'smooth' });
    }

    notificationAlert = (msg, type = "danger") => {
        if (!this._notify) return;
        this._notify.notificationAlert({
            place: 'tc',
            message: (
                <div>
                    <div>
                        <ReactMarkdown
                            source={msg}
                            escapeHtml={false}
                        />
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 30
        });
    }

    isFetching(isFetching = true, fetchingMsg = "") {
        this.setState({ isFetching, fetchingMsg });
    }

    setFootprint(nfp) {
        let fp = [];

        if (nfp.route.name !== "Dashboard") {
            fp = store.get("footprint") ?? [];
            fp.push(nfp);
        }

        console.log("fp", fp);
        store.set("footprint", fp)
    }

    setPageTitle = (pageTitle) => { this.setState({ pageTitle }) };

    authUser = async (token) => {

    }

    render() {
        let users = store.get("users")
        console.log("user", this.state.user)
        if (
            // false && //uncommand it to enable
            !this.state.user) {
            if (this.state.mounted) {
                return <Redirect to="/guest/otpLogin" />
            } else {
                console.log(`Spinner-Spinner-Spinner`)
                return (
                    <Row>
                        <Col xs="12" className="mx-auto mt-5 text-center">
                            <Spinner color="info" style={{ width: '3rem', height: '3rem' }} />
                        </Col>
                    </Row>
                )
            }
        }
        return (
            <div className="wrapper text-centered mp-layout"
                style={{
                    backgroundColor: "#f5f5f5",
                }}
            >
                <section>
                    <nav className="navbar navbar-default navbar-fixed-top p-1"
                        style={{ backgroundColor: "#0250aa", }}
                    >
                        <div className="container-fluid">
                            <div className="navbar-header">
                                <span className="navbar-brand text-white">
                                    {this.state.pageTitle && this.state.pageTitle !== "" ?
                                        <><span onClick={() => {
                                            this.props.history.push("/mp/menu")
                                        }}><i className="fas fa-home cursor-pointer"></i></span> | {this.state.pageTitle}</> :
                                        "Pacific Cross BROKER PORTAL"}
                                </span>
                            </div>
                            <div className="pull-right">
                                <UncontrolledDropdown group>
                                    <Button color="white">
                                       Broker No. {this.state.user?.brokerNo}
                                    </Button>
                                    <DropdownToggle
                                        caret
                                        color="white"
                                    />
                                    <DropdownMenu>
                                        {users.map(bk => (
                                            <DropdownItem 
                                            className="btn-white"
                                            onClick={()=>{
                                                store.set("user",bk)
                                                this.setState({"user":bk})
                                            }}>
                                                {bk.brokerNo}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </div>
                    </nav>
                </section>
                <div className="container-fluid content-body center-block col-11 mx-auto"
                    style={{
                        minHeight: "85vh",
                    }}>

                    <Switch>
                        {routes.map(({ component: Cmp, ...prop }, key) => {
                            return (
                                <Route
                                    path={prop.layout + prop.path}
                                    component={prop.component}
                                    key={key}
                                    render={props => {
                                        //this.setFootprint({...props.location, route:{...prop}});
                                        return <Cmp {...props}
                                            permissions={store.get('permissions')}
                                            notify={this.notificationAlert}
                                            isFetching={this.isFetching}
                                            scrollTo={this.scrollTo}
                                            setPageTitle={this.setPageTitle}
                                            isMP={true}
                                            routes={routes}
                                            user={this.state.user}
                                        />
                                    }}
                                />
                            );
                        })}
                    </Switch>
                    <div className="w-100" />
                    {this.state.pageTitle && this.state.pageTitle !== "" &&
                        <div className="pull-left cursor-pointer my-4"
                            onClick={() => {
                                //this.props.history.push("/mp/menu")
                                window.history.back()
                            }}
                            style={{ color: "#0250aa", fontSize: "14px" }}
                        ><i className="fas fa-arrow-circle-left"></i>&nbsp;Back</div>}
                    <div className="w-100" />
                    <NotificationAlert ref={ref => this._notify = ref} />

                    <Modal isOpen={this.state.isFetching} >
                        <ModalBody >
                            <h3 className="text-center pt-4"><i className="fas fa-spinner fa-spin"></i> Now Loading...</h3>
                        </ModalBody>
                        <ModalFooter>
                            {this.state.fetchingMsg}
                        </ModalFooter>
                    </Modal>
                </div>
                <div className="w-100" />
                <br />
                <Footer fluid />
                {/*
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          handleActiveClick={this.handleActiveClick}
          handleBgClick={this.handleBgClick}
        />
		*/}
            </div>
        );
    }
}

export default MpLayout;
