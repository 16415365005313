import TrackClaimStatus from "views/TrackClaimStatus";
import ClaimSubmitForm from "views/ClaimSubmitForm.js"; 
import OtpLogin from "views/OtpLogin.js"; 
import Logout from "views/Logout.js"; 
import MpMenu from "views/MpMenu.js"; 
import BrokerClaims from "views/BrokerClaims"; 
import BrokerMembers from "views/BrokerMembers"; 
import BrokerPolicies from "views/BrokerPolicies"; 
import BrokerCommissions from "views/BrokerCommissions"; 


var routes = [
 
 {
    path: "/otpLogin/",
    name: "OtpLogin",
    component: OtpLogin,
    layout: "/guest",
  },
 {
    path: "/trackClaimStatus/",
    name: "TrackClaimStatus",
    component: TrackClaimStatus,
    layout: "/guest",
  },
  {
    path: "/menu",
    name: "Menu",
    component: MpMenu,
    layout: "/mp",
	  isNav:false,
    isBkrOnly:false,
  },  

  {
    path: "/claims/:action",
    name: "Claim Submit",
    component: ClaimSubmitForm,
    layout: "/mp",
	  isNav:false,
    isBkrOnly:false,
  },
  {
    path: "/claims/:action/:claimId",
    name: "Claim Submit",
    component: ClaimSubmitForm,
    layout: "/mp",
	  isNav:false,
    isBkrOnly:false,
  },
  {
    path: "/policy",
    name: "Policy Enquiry",
    component: BrokerPolicies,
    layout: "/mp",
    icon:"fas fa-shield-alt",
    desc:"Policy Enquiry",
	  isNav:true,
    isBkrOnly:false,
  },
  {
    path: "/commission",
    name: "Commission Enquiry",
    component: BrokerCommissions,
    layout: "/mp",
    icon:"fas fa-money-check-alt",
    desc:"Commission Enquiry",
	  isNav:true,
    isBkrOnly:false,
  },
  {
    path: "/claim",
    name: "Claims View",
    component: BrokerClaims,
    layout: "/mp",
    icon:"fab fa-wpforms",
    desc:"Claims View",
	  isNav:true,
    isBkrOnly:false,
  },
  {
    path: "/member/:searchQurey",
    name: "Member",
    component: BrokerMembers,
    layout: "/mp",
    icon:"fas fa-user",
    desc:"Member",
	  isNav:false,
    isBkrOnly:false,
  },
  {
    path: "/member",
    name: "Member",
    component: BrokerMembers,
    layout: "/mp",
    icon:"fas fa-user",
    desc:"Member",
	  isNav:true,
    isBkrOnly:false,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    layout: "/mp",
    icon:"fas fa-sign-out-alt",
    desc:"Logout to portal",
	  isNav:true,
    isBkrOnly:false,
  },
];
export default routes;
