import React from "react";
// javascript plugin used to create scrollbars on windows
import { Route, Switch } from "react-router-dom";
import NotificationAlert from 'react-notification-alert';
import ReactMarkdown from 'react-markdown/with-html';
import store from 'store';

import routes from "routes.js";

class GuestLayout extends React.Component {
  componentDidMount() {
    if(!store.get(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)){
      store.clearAll();
      store.set(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`, true);
    }

  }
  componentWillUnmount() {

  }
  componentDidUpdate(e) {

  }

  notificationAlert = (msg, type="danger", dismiss = 3) =>{
    if(!this._notify)return;
    this._notify.notificationAlert({
      place: 'tc',
      message: (
        <div>
          <div>
            <ReactMarkdown
              source={msg}
              escapeHtml={false}
            />
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: dismiss
    });
  }

  render() {
    return (
      <div className="wrapper text-white"  
        style={{ 
          backgroundColor:"#FFF",
        //  backgroundImage:"url('/img/bg.png')",
          backgroundSize:"cover",
          backgroundRepeat:"no-repeat"
        }}>
        <div className="d-inline-block p-3 cursor-pointer m-5 bg-white rounded" onClick={e=>this.props.history.push("/web/welcome")}>
                    <img  alt="Logo" src="/img/logo.png"
                      style={{width:"128px"}}
                      className=""
                    /> &nbsp;
                    </div>
          <NotificationAlert ref={ref=>this._notify=ref} />		
          <Switch>
          {routes.map(({component:Cmp, ...prop}, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                  render={props => { 
                    return<Cmp {...props} 
                              notify={this.notificationAlert} 
                              />
                    }} 
                />
              );
            })}

          </Switch>
      </div>
    );
  }
}

export default GuestLayout;
