import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
//import "assets/demo/demo.css";
import "assets/css/wms-fancy.css";
import "assets/css/min-sidebar.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import 'react-perfect-scrollbar/dist/css/styles.css';
import '@fortawesome/fontawesome-free/js/all.js';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import "react-datepicker/dist/react-datepicker.css";

import MpLayout from "layouts/Mp.js";
import GuestLayout from "layouts/Guest.js";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/mp" render={(props) => <MpLayout {...props} />} />
      <Route path="/guest" render={(props) => <GuestLayout {...props} />} />
      <Redirect to="/guest/OtpLogin" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
