import React from "react";

// reactstrap components
import {
	Row, Col, UncontrolledTooltip, Button, Input,
} from "reactstrap";
import store from 'store';
import dayjs from 'dayjs';
import DataTable from 'react-data-table-component';
//import { Rx, Gx } from "helper/ApiCaller.js";
import axios from 'axios';



class BrokerCommissions extends React.Component {
	constructor(props) {
		super(props);

		//this.Rx = new Rx(this.props.history);
		//this.Gx = new Gx(this.props.history);

		this.commStmtColumns = [

			{
				name: "Filename",
				sortable: true,
				width: "340px",
				selector: "filename",
				cell: row => (<><a target="_blank" href={`${process.env.REACT_APP_API_URL_REPORT}/claim/admin/report/${row.material}`}>{row.filename}</a></>),
			},
			{
				name: "Created at",
				width: "140px",
				selector: "updated",

				sortable: true,
				cell: row => dayjs(row.updated).format('YYYY-MM-DD'),

			},
		]
		this.commColumns = [

			{
				name: "Account Month",
				width: "140px",
				sortable: true,
				selector: "closedAcctMth",
				cell: row => row.closedAcctMth,
			},
			{
				name: "Policy No.",
				width: "180px",
				sortable: true,
				selector: "billingDebitNote.policy.policyNo",
				cell: row => row.billingDebitNote?.policy?.policyNo ?? "--",
			},
			{
				name: "Policyholder",
				width: "300px",
				sortable: true,
				selector: "billingDebitNote.policy.holder_name ",
				cell: row => row.billingDebitNote?.policy?.holder_name ?? "--",
			},
			{
				name: "Paid at",
				width: "140px",
				sortable: true,
				cell: row => row.txExtra.paidDate ? dayjs(row.txExtra.paidDate).format('YYYY-MM-DD') : "--",

			},
			{
				name: "Created at",
				width: "140px",
				selector: "updated",

				sortable: true,
				cell: row => dayjs(row.updated).format('YYYY-MM-DD'),

			},
			{
				name: 'Amount',
				selector: 'txAmt',
				sortable: true,
				cell: row => {
					return row.txAmt + " " + row.txAmtCcy.substring(row.txAmtCcy.length - 3)
				}
			},


		];


		this.state = {
			...this.props.match.params,
			policyList: [],
			policyListFiltered: [],
			reportIndex: [],
			sysCodeList: [],
			selectedItem: null,
			commMthList: [],
			selectedCommMthList: "",
			searchQurey: "",
			tab: "comm"
		};


		this.handleChange = this.handleChange.bind(this);
		this.handleTableChange = this.handleTableChange.bind(this);
		this.searchCommissions = this.searchCommissions.bind(this);
		this.getCommissionsSample = this.getCommissionsSample.bind(this);
		this.getCommissionsApi = this.getCommissionsApi.bind(this);
		this.renderComm = this.renderComm.bind(this);
		this.renderCommStmt = this.renderCommStmt.bind(this);

	}

	componentDidMount() {
		if (this.props.setPageTitle)
			this.props.setPageTitle("Commissions");

		this.props.isFetching();
		Promise.all([
			this.getJsonFile("/data/sysCode.json"),
		])
			.then(function ([sysCodeList]) {

				this.setState({
					sysCodeList,
				}, () => {
					if (store.get("justSubmitedMemberBarcodeId")) {
						this.setState({ searchQurey: store.get("justSubmitedMemberBarcodeId") }, () => {
							store.set("justSubmitedMemberBarcodeId", null);
							this.searchCommissions();
						})
					} else {
						this.searchCommissions();
					}
					//this.props.isFetching(false);
				});
			}.bind(this));



	}


	searchCommissions = async () => {
		this.props.isFetching();
		this.getCommissionsSample("").then(result => {
			this.props.isFetching(false);

			let policyList = result.data
			let reportIndex = result.reportIndex.data
			let commMthList = policyList.map(p => {
				let closedAcctMth = p.closedAcctMth.substring(0, p.closedAcctMth.length - 2) + "-" + p.closedAcctMth.substring(p.closedAcctMth.length - 2)
				p.closedAcctMth = closedAcctMth
				return closedAcctMth
			});
			console.log(commMthList)
			//distict the items in commMthList
			commMthList = commMthList.filter((item, idx, self) => self.indexOf(item) === idx);
			let selectedCommMthList = (commMthList.length > 0) ? commMthList[0] : "";

			this.setState({
				reportIndex,
				policyList,
				commMthList,
				selectedCommMthList
			});
		});
		return;
		/*
				Promise.all([
					this.getCommissions(`filter:"barcodeId=${this.state.searchQurey}"`),
					this.getCommissions(`filter:"claimant={i}${this.state.searchQurey}"`),
					this.getCommissions(`filter:"claimNo=${this.state.searchQurey}"`),
				])
					.then(function (claimSearchResults) {
		
						let policyList = claimSearchResults
										.flatMap(cl=>[...cl]) //merge
										.filter((cl,idx,self)=>(self.findIndex(s=>s._id === cl._id) === idx)); //distinct
						
		
						this.setState({
							policyList,
						});
						this.props.isFetching(false);
					}.bind(this));
					*/
	}




	getCommissionsSample = async (filter) => {
		let result = [];

		result = await this.getCommissionsApi()
		console.log("result", result)
		return result;
	}

	getJsonFile = async (path) => {

		let result = [];

		await axios.get(path)
			.then(function (response) {
				//console.log(response.data);
				result = response.data;
			}).catch(function (e) {
				this.props.notify(e?.response?.data.message.toString() ?? e.toString());
			}.bind(this));


		return result;

	}

	getCommissionsApi = async () => {

		let user = store.get("user");

		console.log(user)
		let result = [];

		await axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}/commissionsTx`, { ...user }).then(data => {
			console.log(data)
			result = data
		})

		await axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}/reportIndex`, { ...user, filter: `{"filename":"COM_STMT_${user.brokerNo}.pdf"}` }).then(data => {
			console.log(data)
			result.reportIndex = data
		})


		return result;

	}

	onRowClicked = (row, event) => {
		console.log("onRowClicked", row, event);
		//this.props.history.push("./claims/view/" + row._id);
	}

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}


	handleTableChange = (state) => {
		// You can use setState or dispatch with something like Redux so we can use the retrieved data
		console.log('Selected Rows: ', state.selectedRows);
	};

	renderComm() {
		return (
			<>
				<div className="content pb-0 mt-2" >
					<Row className="">
						<Col md={"12"}>
							<Row className="rounded bg-light mb-2">
								<Col md="10" className="p-2">
									<Input
										className="my-2 h-75"
										value={this.state.selectedCommMthList}
										onChange={this.handleChange}
										name="selectedCommMthList"
										type="select"
									>
										{this.state.commMthList.map((mth, idx) => (<option key={"cm" + idx} value={mth}>{mth}</option>))}
									</Input>

									<Input type="text"
										className="my-2 h-75 d-none"
										value={this.state.searchQurey}
										name="searchQurey"
										onChange={this.handleChange}
										placeholder="Search: Policy No."
										onKeyPress={e => {
											if (e.key === 'Enter') {
												this.searchCommissions();
											}
										}}
									/>
								</Col>
								<Col md="2" className="p-2 d-none">
									<Button className="my-2 h-75" color="success"
										onClick={this.searchCommissions}
									>Search</Button>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<DataTable
								title="Commissions"
								columns={this.commColumns}
								customStyles={{ rows: { style: { cursor: "pointer" } } }}
								data={this.state.policyList.filter(p => p.closedAcctMth === this.state.selectedCommMthList)}
								//selectableRows // add for checkbox selection
								Clicked
								pagination
								striped
								highlightOnHover
								noContextMenu
								onSelectedRowsChange={this.handleTableChange}
								onRowClicked={this.onRowClicked}

							/>
						</Col>
					</Row>
				</div>
			</>
		);
	}

	renderCommStmt() {
		return (
			<>
				<div className="content pb-0 mt-2" >

					<Row>
						<Col md="12">
							<DataTable
								title="Commissions Statement"
								columns={this.commStmtColumns}
								customStyles={{ rows: { style: { cursor: "pointer" } } }}
								data={this.state.reportIndex}
								//selectableRows // add for checkbox selection
								Clicked
								pagination
								striped
								highlightOnHover
								noContextMenu
							//onSelectedRowsChange={this.handleTableChange}
							//onRowClicked={this.onRowClickedStmt}

							/>
						</Col>
					</Row>
				</div>
			</>
		);
	}

	render() {
		return (
			<>
				<Button 
				disabled={this.state.tab === "comm"}
				onClick={
					() => {
						this.setState({ tab: "comm" })
					}
					
				}>Commission</Button>
				<Button 
				disabled={this.state.tab === "commStmt"}
				onClick={
					() => {
						this.setState({ tab: "commStmt" })
					}
				} className="ml-2">
					Commission Statement</Button>
				{this.state.tab === "comm" && this.renderComm()}
				{this.state.tab === "commStmt" && this.renderCommStmt()}
			</>
		);
	}
}

export default BrokerCommissions;
