import React from "react";

// reactstrap components
import {
	Row, Col, UncontrolledTooltip, Button, Input,
	Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import store from 'store';
import dayjs from 'dayjs';
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
//import { Rx, Gx } from "helper/ApiCaller.js";
import axios from 'axios';
import { JSONTree } from 'react-json-tree';
import Swal from 'sweetalert2';
import ReactDOMServer from 'react-dom/server';
import dl from 'helper/MaterialDownload'
import { lb } from "date-fns/locale";

// data provides access to your row data
const ExpandedComponent = ({ data }) => {

	let ln = data.lines[0];
	let header=<tr>
	{
		Object.keys(ln).map(k => <td key={ln["lineNo"] + '-' + k} className="text-nowrap p-2">
			<label style={{}}>{k.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()).replace("Pay Date","CSR Date")}</label>
		</td>)}
	</tr>

	return <table border="1"><thead>{header}</thead><tbody>{data.lines
		.sort((a,b) => parseInt(a.lineNo) - parseInt(b.lineNo))
		.map(ln => {

		return <tr key={ln["lineNo"]} style={{color:"DarkBlue"}}>
			{
				Object.keys(ln).map(k => { 
					
					return<td key={ln["lineNo"] + '-' + k} className="text-nowrap p-2">
					{
						typeof ln[k] === "string" && ln[k].includes("com.ial") ? ln[k].split('_').pop() :
							//	typeof ln[k] === "string" && k.includes("Date") ? dayjs(ln[k]).format('DD/MM/YYYY') :
							(ln?.[k].toString() ?? "")}
				</td>})}
		</tr>
	})}</tbody></table>;
};


class BrokerClaims extends React.Component {

	
	constructor(props) {
		super(props);

		//this.Rx = new Rx(this.props.history);
		//this.Gx = new Gx(this.props.history);

		this.claimColumns = [
			{
				name: 'Claim No.',
				selector: 'claimNo',
				width: "140px",
				sortable: true,
				cell: row => {
					return <>{row.claimNo}&nbsp;<a className="d-none" title="Image" href={`http://192.168.8.85/DocufloSearch/Download.aspx?ProfileName=Claims&KeyField1=Claim%20Number&Keyvalue1=${row.claimNo}`}>
						<i className={"fas fa-file"} style={{ fontSize: "1.5em" }}></i>
					</a></> ?? " -- "

				}
			},
			{
				name: 'Claimant',
				selector: 'claimant',
				width: "280px",
				sortable: true,
			},
			{
				name: 'Received Date',
				selector: 'receivedDate',
				sortable: true,
			},
			{
				name: 'Incurred Date',
				selector: 'incurredDate',
				sortable: true,
			},
			/*
			{
				name: 'Benefit',
				selector: 'benefit',
				sortable: true,
				width: "480px",

			},
			*/
			{
				name: 'Status',
				selector: 'status',
				sortable: true,
				width: "180px",
			},
			{
				name: 'Submitted Amt.',
				sortable: true,
				selector: 'submittedAmt',
			},
			{
				name: 'Approved Amt.',
				selector: 'approvedAmt',
				sortable: true,
			},
			{
				name: 'CSR',
				selector: 'status',

				cell: row => {
					return <>
						{(row.status === "Fully Complete" || row.status === "Partially Complete") && 
						(row.notification?.email?.completedBC !== null || row.notification?.email?.completedCC !== null) &&
						<a href="#" style={{ fontSize: "1.05em" }} className="badge badge-info" onClick={() => {
							this.getClaimFiles(row)
						}}><i className={"fas fa-file"}></i>&nbsp;CSR</a>}
					</>

				}
			}
		];

		this.lineColumns = [
			{
				name: "Rcv Date",
				selector: "rcvDate",
				sortable: true,
			},
			{
				name: "Incur Date From",
				selector: "incurDateFrom",
				sortable: true,
			},
			{
				name: "Line Status",
				selector: "lineStatus",
				sortable: true,
			},
			{
				name: "Claim No",
				selector: "claimNo",
				sortable: true,
			},
			{
				name: "Line No",
				selector: "lineNo",
				sortable: true,
			},
			{
				name: "Pres Amt",
				selector: "presAmt",
				sortable: true,
			},
			{
				name: "Pres Ccy",
				selector: "presCcy",
				sortable: true,
			},
			{
				name: "App Amt",
				selector: "appAmt",
				sortable: true,
			},
			{
				name: "App Amt Ccy",
				selector: "appAmtCcy",
				sortable: true,
			},
			{
				name: "Ben Spend",
				selector: "benSpend",
				sortable: true,
			},
			{
				name: "Pending Codes",
				selector: "pendingCodes",
				sortable: true,

			},
			{
				name: "Remark Codes",
				selector: "remarkCodes",
				sortable: true,
				width: "280px",

			},
			{
				name: "Payee",
				selector: "payee",
				sortable: true,
				width: "280px",

			},
			{
				name: "Pay Date",
				selector: "payDate",
				sortable: true,
			},
			{
				name: "Pay Ccy",
				selector: "payCcy",
				sortable: true,
			},
			{
				name: "Pay Amt",
				selector: "payAmt",
				sortable: true,
			},
			{
				name: "Prov Name",
				selector: "provName",
				sortable: true,
				width: "280px",

			},
			{
				name: "Update Date",
				selector: "updated",
				sortable: true,
			},
			{
				name: "Create Date",
				selector: "created",
				sortable: true,
			},
			{
				name: "Benefit Type",
				selector: "benefitType",
				sortable: true,
			},
			{
				name: "Benefit Head",
				selector: "benefitHead",
				sortable: true,
			}
		]

		this.state = {
			...this.props.match.params,
			claimList: [],
			lineList: [],
			sysCodeList: [],
			selectedItem: null,
			userList: [],
			userPosListEnabled: [],
			userPosListAvailable: [],
			userFilterTemp: "",
			userFilter: "",
			searchQurey: "",
			tab: "claim",
			modal : false,
		};


		this.handleChange = this.handleChange.bind(this);
		this.handleTableChange = this.handleTableChange.bind(this);
		this.searchClaims = this.searchClaims.bind(this);
		this.getClaimsSample = this.getClaimsSample.bind(this);
		this.getClaimsApi = this.getClaimsApi.bind(this);
		this.getClaimFiles = this.getClaimFiles.bind(this);
	}

	componentDidMount() {
		if (this.props.setPageTitle)
			this.props.setPageTitle("Claims");

		this.props.isFetching();
		Promise.all([
			this.getJsonFile("/data/sysCode.json"),
		])
			.then(function ([sysCodeList]) {

				this.setState({
					sysCodeList,
				}, () => {
					/*
					if (store.get("justSubmitedClaimBarcodeId")) {
						this.setState({ searchQurey: store.get("justSubmitedClaimBarcodeId") }, () => {
							store.set("justSubmitedClaimBarcodeId", null);
							this.searchClaims();
						})
					} else {
						this.searchClaims();
					}
					*/
					this.props.isFetching(false);
				});
			}.bind(this));



	}


	toggle = () => this.setState({modal : !this.state.modal});

	getClaimFiles = async (row) => {
		this.props.isFetching();
		let user = store.get("user");

		await axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}/reportIndex`, { ...user, filter: `{"parentRefNo":"${row.refNo}"}` }).then(data => {
			this.props.isFetching(false);
			let list = data.data.filter(d => (d.reportId?.indexOf("CLSETTRPT01_BC") !== -1 || d.reportId?.indexOf("CLSETTRPT01_CC") !== -1 ) && !d.filename.includes("PREVIEW"))

			let csrList = <ol>
				{list.map(l => {
					return <li id={l._id} 
					className="cursor-pointer"
					onClick={()=>{
						dl(l.material,l.filename)
					}}>{l.filename}</li>
				})}
				{list.length === 0 && <li>No CSR</li>}
			</ol>

			this.setState({modal:true, csrList})

		})


	}

	searchClaims = async () => {
		this.props.isFetching();
		this.getClaimsSample("").then(result => {

			let claimList = result.data.map(cl => {


				cl.status= this.state.sysCodeList.find(s => s.codeType === "CL_STATUS" && s.refNo === cl.status)?.desc ?? "Vaildating"
				cl.status = cl.claimNo ? cl.status : "Pending"
				//let statusCode = cl.refProps.find(p => p.key === "status.code")
				//if (statusCode) cl.status = this.state.sysCodeList.find(s => s.codeType === "CL_STATUS" && s.code === statusCode.values[0])?.desc ?? "Vaildating"
				if(cl.status === "Request Approval" || cl.status === "New Claim" || cl.status === "Pending Validation" || cl.status === "Request Checking") cl.status = "Under Processing"

				let isCSR = (cl.status === "Fully Complete" || cl.status === "Partially Complete") &&  (cl.notification?.email?.completedBC !== null || cl.notification?.email?.completedCC !== null)

				let sa = 0
				let presCcy = ""
				let aa = 0
				let payCcy = ""

				sa = cl.lines?.reduce((a, v) => {
					a += v.orgPresAmt
					presCcy = v.presCcy ?? presCcy
					return a
				}, 0)

				cl.submittedAmt = (sa?.toFixed(2) ?? "0.00") + " " + presCcy?.substring(presCcy.length - 3)

				aa = cl.lines?.reduce((a, v) => {
					a += v.payAmt
					payCcy = v.payCcy ?? payCcy
					return a
				}, 0)


				
				cl.lines = cl.lines.flatMap(ln => {

					ln.lineStatus = this.state.sysCodeList.find(s => s.refNo === ln.lineStatus)?.desc

					if(cl.status == "Under Processing"){
						ln.lineStatus = " -- "
					}

					//ln.benefitType = ln?.benefit?.benType ?? ""
					//ln.benefitHead = ln?.benefit?.benHead ?? ""
					delete ln.benefit
					delete ln.diagnosis
					//ln.diagnosisCode = ln?.diagnosis.code ?? ""
					//ln.diagnosisDesc = ln?.diagnosis.desc ?? ""
					
					//ln.updated = dayjs(ln.updated).format('DD/MM/YYYY')
					//ln.created = ln.created ? dayjs(ln.created).format('DD/MM/YYYY') : (ln.updated ?? " -- ")
					//if(ln.fxRate)
						//ln.presAmt = parseFloat(ln.presAmt * ln.fxRate).toFixed(2)
					ln.orgPresAmt = ln.orgPresAmt.toFixed(2)

					delete ln.fxRate
					delete ln.presAmt

					//if(ln.payFxRate)
					//	ln.payAmt = ln.presAmt * ln.payFxRate

					delete ln.benSpend
					delete ln.updated
					delete ln.created

					if(!isCSR){
						ln.payAmt = " -- "
						lb.payDate = " -- "
					}

					Object.keys(ln).forEach(k => {
						ln[k] = typeof ln[k] === "string" && ln[k].includes("com.ial") ? ln[k].split('_').pop() :
							ln[k] instanceof Array && ln[k]?.[0]?.includes("com.ial") ? ln[k].map(item => item.split('_').pop()).join() :
								typeof ln[k] === "string" && ln[k] !== "" && k.includes("Date") ? dayjs(ln[k]).format('MM/DD/YYYY') :
									(ln?.[k] ?? "")
					})

					

					return [ln]
				})
				cl.approvedAmt = isCSR ? (aa?.toFixed(2) ?? "0.00") + " " + payCcy?.substring(payCcy.length - 3) : " -- "



				let benList = cl.refProps.find(p => p.key === "line.benefit.desc.en")
				//cl.benefit = benList ? benList.values.join(", ") : " -- "

				let name = cl.refProps.find(p => p.key === "line.membership.contacts.name.short")
				cl.claimant = name ? name.values[0] : " -- "

				cl.incurredDate = cl.lines?.[0]?.incurDateFrom 	
				cl.receivedDate = cl.lines?.[0]?.rcvDate 

				return cl
			})

			let lineList = claimList.flatMap(cl => cl.lines)

			if (this.state.tab === "claim" && this.state.searchQurey !== "") {
				claimList = result.data
					.filter(row => {
						row.name = row.refProps.find(p => p.key === "line.membership.contacts.name.short")?.values[0] ?? ""
						row.memberNo = row.refProps.find(p => p.key === "line.membership.memberNo")?.values[0] ?? ""

						if (
							row.claimNo?.toUpperCase().indexOf(this.state.searchQurey?.toUpperCase().trim()) !== -1 ||
							row.barcode?.toUpperCase() === this.state.searchQurey?.toUpperCase().trim() ||
							row.memberNo?.toUpperCase().includes(this.state.searchQurey?.toUpperCase().replace("-", "").trim()) ||
							row.name.toUpperCase().indexOf(this.state.searchQurey?.toUpperCase().trim()) !== -1
						) {
							return true
						}
						return false
					})
			}
			this.props.isFetching(false);
			this.setState({
				claimList,
				lineList
			});
		});
		return;
		/*
				Promise.all([
					this.getClaims(`filter:"barcodeId=${this.state.searchQurey}"`),
					this.getClaims(`filter:"claimant={i}${this.state.searchQurey}"`),
					this.getClaims(`filter:"claimNo=${this.state.searchQurey}"`),
				])
					.then(function (claimSearchResults) {
		
						let claimList = claimSearchResults
										.flatMap(cl=>[...cl]) //merge
										.filter((cl,idx,self)=>(self.findIndex(s=>s._id === cl._id) === idx)); //distinct
						
		
						this.setState({
							claimList,
						});
						this.props.isFetching(false);
					}.bind(this));
					*/
	}




	getClaimsSample = async (filter) => {
		let result = [];

		result = await this.getClaimsApi()
		console.log("result", result)
		return result;
	}

	getJsonFile = async (path) => {

		let result = [];

		await axios.get(path)
			.then(function (response) {
				//console.log(response.data);
				result = response.data;
			}).catch(function (e) {
				this.props.notify(e?.response?.data.message.toString() ?? e.toString());
			}.bind(this));


		return result;

	}

	getClaimsApi = async () => {

		let user = store.get("user");

		console.log(user)
		let result = [];

		await axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}/claims`, 
		{ ...user ,
			searchQurey : this.state.searchQurey.trim()
		}
		).then(data => {
			console.log(data)
			result = data
		})


		return result;

	}

	onRowClicked = (row, event) => {
		console.log("onRowClicked", row, event);
		//this.props.history.push("./claims/view/" + row._id);
	}

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}


	handleTableChange = (state) => {
		// You can use setState or dispatch with something like Redux so we can use the retrieved data
		console.log('Selected Rows: ', state.selectedRows);
	};

	render() {
		return (
			<>
				<Button
					disabled={this.state.tab === "claim"}
					onClick={
						() => {
							this.setState({ tab: "claim" })
						}

					}>Claim</Button>
				<Button
					disabled={this.state.tab === "line"}
					onClick={
						() => {
							this.setState({ tab: "line" })
						}
					} className="ml-2 d-none">Line</Button>
				<div className="content pb-0 mt-2" >
					<Row>
						<Col md={"12"}>
							<Row className="rounded bg-light mb-2">
								<Col md="10" className="p-2">
									<Input type="text"
										className="my-2 h-75"
										value={this.state.searchQurey}
										name="searchQurey"
										onChange={this.handleChange}
										placeholder="Search: Claim No./ Member No. / Member Name "
										onKeyPress={e => {
											if (e.key === 'Enter') {
												this.searchClaims();
											}
										}}
									/>
								</Col>
								<Col md="2" className="p-2">
									<Button className="my-2 h-75" color="success"
										onClick={this.searchClaims}
									>Search</Button>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<DataTable
								title="Claims"
								columns={this.state.tab === "claim" ? this.claimColumns : this.lineColumns}
								customStyles={{ rows: { style: { cursor: "pointer" } } }}
								data={this.state.tab === "claim" ? this.state.claimList : this.state.lineList}
								//selectableRows // add for checkbox selection
								Clicked
								pagination
								striped
								highlightOnHover
								noContextMenu
								autoWidth={true}
								onSelectedRowsChange={this.handleTableChange}
								onRowClicked={this.onRowClicked}
								expandableRows={this.state.tab === "claim"}
								expandableRowDisabled={row => row.disabled}
								expandableRowsComponent={<ExpandedComponent />}
							/>
						</Col>
					</Row>
				</div>

				<Modal isOpen={this.state.modal} toggle={this.toggle} >
					<ModalHeader toggle={this.toggle}>CSR</ModalHeader>
					<ModalBody>
						{this.state.csrList}
					</ModalBody>
				</Modal>

			</>
		);
	}
}

export default BrokerClaims;
