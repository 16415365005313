import React from "react";

// reactstrap components
import {
	Row, Col, UncontrolledTooltip, Button, Input,
	Modal, ModalHeader, ModalBody, ModalFooter

} from "reactstrap";
import store from 'store';
import dayjs from 'dayjs';
import DataTable from 'react-data-table-component';
//import { Rx, Gx } from "helper/ApiCaller.js";
import axios from 'axios';
import Swal from 'sweetalert2';
import dl from 'helper/MaterialDownload'




class BrokerPolicies extends React.Component {
	constructor(props) {
		super(props);

		//this.Rx = new Rx(this.props.history);
		//this.Gx = new Gx(this.props.history);

		this.claimColumns = [
			{
				name: 'Policy#',
				selector: 'policyNo',
				width: "200px",
				sortable: true,
				cell: row => {
					//let pocyNo = row.refProps.find(p => p.key === "policyNo")

					return row.policyNo ?? " -- "
				}
			},
			{
				name: <>{'Policy Year'}<br />{'(No. of Year)'}</>,
				selector: 'effDate',
				width: "140px",
				sortable: true,
				cell: row => dayjs(row.effDate).format('YYYY') + ` (${row.noOfYr ?? 1})`,
			},
			{
				name: 'Policyholder',
				selector: 'pohoName',
				sortable: true,
				cell: row => {
					return row.pohoName ?? " -- "
				}
			},
			{
				name: <>{'Period'}<br />{'(Effective Date)'}<br />{'(Expiry Date)'}</>,
				selector: 'effDate',
				width: "140px",
				sortable: true,
				cell: row => <>{dayjs(row.effDate).format('DD/MM/YYYY')}<br />{dayjs(row.expDate).format('DD/MM/YYYY')}</>,
			},
			{
				name: 'Schedule of Benedits',
				selector: 'policyPlanDesc',
				sortable: true,
				cell: row => {
					return row.policyPlanDesc ? <><span className="cursor-pointer text-info" 
						onClick={()=>{
							this.getBSFiles(row)
					}}><i className={"fas fa-file"} style={{ fontSize: "1.5em" }}></i></span>&nbsp;&nbsp;{row.policyPlanDesc}</> : " -- "
				}
			},
			{
				name: '',
				//selector: 'claimant',
				sortable: false,
				width: "100px",
				cell: row => {
					return <Button color="success" size="sm" title="Members" onClick={() => {
						this.props.history.push('/mp/member/' + row.policyNo);

					}}>
						<i className={"fas fa-user-friends"}></i>
					</Button>
				}
			},

		];


		this.state = {
			...this.props.match.params,
			policyList: [],
			sysCodeList: [],
			selectedItem: null,
			userList: [],
			userPosListEnabled: [],
			userPosListAvailable: [],
			userFilterTemp: "",
			userFilter: "",
			searchQurey: "",
			modal: false,

		};


		this.handleChange = this.handleChange.bind(this);
		this.handleTableChange = this.handleTableChange.bind(this);
		this.searchPolicies = this.searchPolicies.bind(this);
		this.getPoliciesSample = this.getPoliciesSample.bind(this);
		this.getPoliciesApi = this.getPoliciesApi.bind(this);
		this.getPolicyholderApi = this.getPolicyholderApi.bind(this);

	}

	componentDidMount() {
		if (this.props.setPageTitle)
			this.props.setPageTitle("Policies");

		this.props.isFetching();
		Promise.all([
			this.getJsonFile("/data/sysCode.json"),
		])
			.then(function ([sysCodeList]) {
				this.props.isFetching(false);
				this.setState({
					sysCodeList,
				}, () => {
					/*
					let user = store.get("user")
					if(user?.headOfficeCde !== "##SOS##")
						this.searchPolicies();
					*/
				});
			}.bind(this));



	}


	searchPolicies = async () => {
		this.props.isFetching();
		this.getPoliciesSample("").then(async result => {

			if(this.state.searchQurey !== "" ){
				let nameSearch = await this.getPoliciesSample(`"holder_name":{"$regex":"${this.state.searchQurey?.toUpperCase()}"}`) 
				result.data = [...result.data, ...nameSearch.data]
			}

			return result
		}).then(result => {

			let policyList = result.data.map(row => {
				row.policyNo = row.refProps.find(p => p.key === "policyNo")?.values[1] ?? ""
				row.pohoName = row.refProps.find(p => p.key === "holder.name.short")?.values[0] ?? ""
				row.policyPlanDesc = row.refProps.find(p => p.key === "policyPlan.plan.desc")?.values[0] ?? ""
				return row;
			})
			if (this.state.searchQurey !== "") {
				policyList = policyList
					.filter(row => {
						if (
							row.policyNo?.toUpperCase().includes(this.state.searchQurey?.toUpperCase()) ||
							row.pohoName?.toUpperCase().includes(this.state.searchQurey?.toUpperCase())
						) {
							return true
						}
						return false
					})
			}
			this.props.isFetching(false);
			this.setState({
				policyList
			});
		});
		return;
		/*
				Promise.all([
					this.getPolicies(`filter:"barcodeId=${this.state.searchQurey}"`),
					this.getPolicies(`filter:"claimant={i}${this.state.searchQurey}"`),
					this.getPolicies(`filter:"claimNo=${this.state.searchQurey}"`),
				])
					.then(function (claimSearchResults) {
		
						let policyList = claimSearchResults
										.flatMap(cl=>[...cl]) //merge
										.filter((cl,idx,self)=>(self.findIndex(s=>s._id === cl._id) === idx)); //distinct
						
		
						this.setState({
							policyList,
						});
						this.props.isFetching(false);
					}.bind(this));
					*/
	}




	getPoliciesSample = async (filter) => {
		let result = [];

		result = await this.getPoliciesApi(filter)
		console.log("result", result)
		return result;
	}

	getJsonFile = async (path) => {

		let result = [];

		await axios.get(path)
			.then(function (response) {
				//console.log(response.data);
				result = response.data;
			}).catch(function (e) {
				this.props.notify(e?.response?.data.message.toString() ?? e.toString());
			}.bind(this));


		return result;

	}

	getPoliciesApi = async (searchQurey="") => {

		let user = store.get("user");

		console.log(user)
		let result = [];

		await axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}/policies`, 
		{ ...user ,
			searchQurey
		}
		).then(data => {
			console.log(data)
			result = data
		})


		return result;

	}

	getPolicyholderApi = async (holderRefNo) => {

		let user = store.get("user");

		console.log(user)
		let result = [];

		await axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}/policyholders`, { ...user, holderRefNo }).then(data => {
			console.log(data)
			result = data
		})


		return result;

	}

	onRowClicked = (row, event) => {
		console.log("onRowClicked", row, event);
		this.props.isFetching()
		this.getPolicyholderApi(row.holderRefNo).then(response => {
			this.props.isFetching(false)
			console.log(response)
			let holder = response.data[0]
			Swal.fire({
				title: 'Policy Details',
				html: `<div class="text-left"><ul>
							<li><b>Policy No:</b> ${row.refProps.find(p => p.key === "policyNo")?.values[1] ?? ""}</li>
							<li><b>Policy Year:</b> ${dayjs(row.effDate).format('YYYY')} (${row.noOfYr ?? 1})</li>
							<li><b>Period:</b> ${dayjs(row.effDate).format('DD/MM/YYYY')} - ${dayjs(row.expDate).format('DD/MM/YYYY')}</li>
							<li><b>Schedule of Benfits:</b> ${row.refProps.find(p => p.key === "policyPlan.plan.desc")?.values[0] ?? ""}</li>
							<li><b>Policyholder:</b> ${row.refProps.find(p => p.key === "holder.name.short")?.values[0] ?? ""}</li>
							<li><b>Title:</b> ${holder.title ?? "--"}</li>
							<li><b>Sex:</b> ${holder.sex ?? "--"}</li>
							<li><b>Address:</b><div>
								${holder.billAddr1 ?? ""}</br>
								${holder.billAddr2 ?? ""}</br>
								${holder.billAddr3 ?? ""}</br>
								${holder.billAddr4 ?? ""}</br>
								${this.state.sysCodeList.find(s => s.refNo === holder.countryBillAddr)?.desc ?? ""}
							</div>
							</li>
							<li><b>Mobile No:</b> ${holder.mobileNo ?? ""}</li>
							<li><b>Office No:</b> ${holder.officeNo ?? ""}</li>
							<li><b>Email:</b> ${holder.email ?? ""}</li>
						</ul></div>`,
				width: "85%"
			});

		})
	}

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}


	handleTableChange = (state) => {
		// You can use setState or dispatch with something like Redux so we can use the retrieved data
		console.log('Selected Rows: ', state.selectedRows);
	};

	toggle = () => this.setState({ modal: !this.state.modal });

	getBSFiles = async (row) => {
		this.props.isFetching();
		let user = store.get("user");

		await axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}/reportIndex`, { ...user, filter: `{"reportId":"PDBNSPRPT01", "references.policiesRefNo":"${row.refNo}"}` }).then(data => {
			this.props.isFetching(false);
			let list = data.data //.filter(d => d.reportId === "CLSETTRPT01_CC" && !d.filename.includes("PREVIEW"))

			let bsList = <ol>
				{list.map(l => {
					return <li id={l._id}
						className="cursor-pointer"
						onClick={() => {
							dl(l.material, l.filename)
						}}>{l.filename}</li>
				})}
				{list.length === 0 && <li>No Benefit Schedule</li>}
			</ol>

			this.setState({ modal: true, bsList })

		})


	}

	render() {
		return (
			<>
				<div className="content pb-0 mt-2" >
					<Row>
						<Col md={"12"}>
							<Row className="rounded bg-light mb-2">
								<Col md="10" className="p-2">
									<Input type="text"
										className="my-2 h-75"
										value={this.state.searchQurey}
										name="searchQurey"
										onChange={this.handleChange}
										placeholder="Search: Policy No. / Policyholder name"
										onKeyPress={e => {
											if (e.key === 'Enter') {
												this.searchPolicies();
											}
										}}
									/>
								</Col>
								<Col md="2" className="p-2">
									<Button className="my-2 h-75" color="success"
										onClick={this.searchPolicies}
									>Search</Button>
									<Button className="my-2 h-75"
										onClick={(e) => {
											this.setState({ searchQurey: "" }, () => {
												this.searchPolicies();
											})
										}}
									>Clear</Button>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<DataTable
								title="Policies"
								columns={this.claimColumns}
								customStyles={{ rows: { style: { cursor: "pointer" } } }}
								data={this.state.policyList}
								//selectableRows // add for checkbox selection
								Clicked
								pagination
								striped
								highlightOnHover
								noContextMenu
								onSelectedRowsChange={this.handleTableChange}
								onRowClicked={this.onRowClicked}

							/>
						</Col>
					</Row>
				</div>


				<Modal isOpen={this.state.modal} toggle={this.toggle} >
					<ModalHeader toggle={this.toggle}>Benefit Schedule</ModalHeader>
					<ModalBody>
						{this.state.bsList}
					</ModalBody>
				</Modal>

			</>
		);
	}
}

export default BrokerPolicies;
