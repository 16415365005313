/*eslint-disable*/
import axios from 'axios';


const MaterialDownload = async (materialId, filename)=>{
	axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}/downloadMaterial`, {materialId})
	.then(url=>{
		const link = document.createElement('a');
		link.href = url.data;
		link.download = filename;
		link.click();
		console.log(filename)
	})

}

export default MaterialDownload;
