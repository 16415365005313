import React, {useState}  from "react"

import axios from 'axios';
// reactstrap components
import {
	Row,
	Col,
	Button,
	Badge,
	Label,
	Input,
	Spinner
} from "reactstrap";
import store from 'store';


const OtpLogin = (props) => {
	const [email, setEmail] = useState("")
	const [otp, setOtp] = useState("")
	const [errMsg, setErrMsg] = useState("")
	const [infoMsg, setInfoMsg] = useState("")
	const [isFetching, setIsFetching] = useState(false)
	const [isShowOTP, setIsShowOTP] = useState(false)


	const getOTP = async () => {
		//setIsFetching(true)
		setInfoMsg("")
		setErrMsg("")
		
		axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}/OTP`,{email})
			.then((response) => {
				console.log(response)
				setIsFetching(false)
				setIsShowOTP(true)

				if(response?.data?.response?.msg)
					setInfoMsg(response?.data?.response?.msg ?? "Service Unavailable, Please try again later.")

			}).catch(e=>{
				console.log(e.response)
				if(e?.response?.data?.error){
					setErrMsg(e.response.data.error)
					setIsShowOTP(false)

				}
				setIsFetching(false)
			})
			
			
	}
	
	const checkOTP = async () => {
		
		setIsFetching(true)
		setInfoMsg("")
		setErrMsg("")
		axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}/login`,{email,otp})
			.then((response) => {
				console.log(response.data)
				setIsFetching(false)
				store.set("user",response.data[0])
				store.set("users",response.data)
				props.history.push("/mp/menu")
			}).catch(e=>{
				console.log(e.response)
				if(e?.response?.data?.error){
					setErrMsg(e.response.data.error)
				}
				setIsFetching(false)
			})
			

	}

	return (
		<div className="content">
			<Row className=" mx-0 fade-in">
				<Col lg="4" md="6" sm="11" className="mx-auto bg-light rounded border text-dark p-5">
					<Row className="">
						<Col md="12">
							<h3>myBroker Portal &nbsp; 
							{isFetching &&<Spinner color="info" />}
							</h3>
						</Col>
						{errMsg !== "" && <Col md="12">
							<Badge color="danger">{errMsg}</Badge>
						</Col>}
						{infoMsg !== "" && <Col md="12">
							<Badge color="info">{infoMsg}</Badge>
						</Col>}
						<div className="w-100"></div>
						<Col md="12" className="mb-0 pb-0">
							<Label>Email</Label>
							<Input type="input" name="email"
								style={{height:"51px", fontSize:"2em"}}
								value={email}
								onChange={e => setEmail(e.target.value)}
							/>
						</Col>
						
						<Col md="12" className="mt-0 pt-0">
							<Button block 
							style={{backgroundColor:"#0250aa"}}
							onClick={e=>{getOTP()}}
							className="btn-lg mt-0"><i className="fas fa-key"></i>  Get OTP</Button>
						</Col>
	
					</Row>
					<Row className={isShowOTP ? "" : "d-none"}>
					<Col md="12" className="mb-0 pb-0">
							<Label>One Time Password</Label>
							<Input type="input" name="otp"
								style={{height:"51px", fontSize:"2em"}}
								value={otp}
								onChange={e => setOtp(e.target.value)}
							/>
						</Col>

						<Col md="12">
							<Button block 
							style={{backgroundColor:"#0250aa"}}
							onClick={e=>{checkOTP()}}
							className="btn-lg mt-0"><i className="fas fa-file-import"></i> Login </Button>
						</Col>
						
					</Row>
				</Col>
			</Row>
		</div>
	)
}


export default OtpLogin;
